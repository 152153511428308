nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 99;
  transition:  0.32s ease-in-out;
  background-color: #c75425;
  height: 10%;
}
.scrolled {
  padding: 0px 0px;
  background-color: #c75425;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
font-weight: 700;
color: #fff;
border: 1px solid #fff;
padding: 18px 34px;
font-size: 18px;
margin-left: 18px;
position: relative;
background-color: transparent;
transition: 0.3s ease-in-out;
}
.navbar-text button span {
z-index: 1;
}
.navbar-text button::before {
content: "";
width: 0%;
height: 100%;
position: absolute;
background-color: #fff;
left: 0;
top: 0;
z-index: -1;
transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
color: #c75425;
}
.navbar-text button:hover::before {
content: "";
width: 100%;
height: 100%;
position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
width: 24px;
height: 17px;
background-image: none;
position: relative;
border-bottom: 2px solid #fff;
transition: all 300ms linear;
top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
width: 24px;
position: absolute;
height: 2px;
background-color: #fff;
top: 0;
left: 0;
content: '';
z-index: 2;
transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
transform: rotate(45deg);
background-color: #fff;
height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
transform: translateY(8px) rotate(-45deg);
background-color: #fff;
height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
border-color: transparent;
}
.solutions-wrapper {
  position: relative; 
}

.solutions-card {
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50px;
  left: -100px;
  z-index: 10;
  width: 500px;
}

.solutions-column {
  flex: 1; 
  margin: 0 10px; 
}

.solutions-column h3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.solutions-column p {
  margin-bottom: 10px;
  font-size: 16px;
}

.services-wrapper {
  position: absolute; 
}

.services-content-container {
  position: absolute;
  top: 50px; 
  left: -200px;
  z-index: 10;
  background-color: #fff;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px; 
  padding-bottom: 0; /* Remove padding at the bottom */
  margin-bottom: 0;  /* Remove margin at the bottom */
}

/* App Container Flexbox Layout */
.services-container {
  display: flex;
  justify-content: space-between;
  padding: -100px;
  background-color: #fff;
}

/* Left Section Styles */
.left-section {
  background-color: #fff;
  padding: 10px;
  width: 90%; /* Flexibility in width for responsive design */
}


/* Right Section Styles */
.right-section {
  padding: 10px;
  width: 40%; /* Ensure right section takes half the space */
  text-align: left; /* Ensure text is aligned to the left */
}

/* Adjust List Styles */
.right-section ul {
  padding-left: 25px; /* Add padding to push the list to the right */
  font-size: 16px; /* Font size for the list items */
}

.right-section li {
  margin-bottom: 10px;
  font-weight: 500;
  padding-left: 30px; /* Add extra padding to push individual items more to the right */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .services-container {
    flex-direction: column; /* Stack the sections vertically on smaller screens */
  }
  .left-section, .right-section {
    width: 100%; /* Full width for each section in mobile view */
  }
  .services-content-container {
    width: 100%; /* Make the content container full width on mobile */
    left: 0;
    top: 60px; /* Adjust the top positioning for smaller screens */
  }
}
/* Left Section Heading Styles */
.left-section h1 {
  font-size: 23.5px; /* Increase font size for h1 */
  margin-bottom: 10px;
  color: black;
}

.left-section h2 {
  font-size: 23.5px; /* Increase font size for h2 */
  margin-bottom: 10px;
  color: black;
}

.left-section h3 {
  font-size: 23.5px; /* Increase font size for h3 */
  margin-bottom: 10px;
  color: black;
}

.left-section h4 {
  font-size: 23.5px; /* Increase font size for h4 */
  margin-bottom: 10px;
  color: black;
}

.left-section h5 {
  font-size: 23.5px; /* Increase font size for h5 */
  margin-bottom: 15px;
  color: black;
}


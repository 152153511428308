/* Container for the entire section */
.Difference-Container {
    padding: 50px 40px;
    background-color: #D9D9D9; /* Gray background */
    color: white;
    text-align: center;
}

.Difference-Container h1{
    color: #000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
}

/* Flexbox container to align boxes and headers in a row */
.Difference {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* Box container */
.Box-Row {
    display: flex;
    justify-content: space-around; /* Adds space between the boxes */
    gap: 250px; /* Adds space between the boxes */
    margin-bottom: 40px; /* Adjusted space between boxes and headers */
}

/* Header container */
.Header-Row {
    display: flex;
    justify-content: space-around; /* Aligns headers in the same row */
    gap: 320px; /* Adds space between the headers */
}

/* Common styling for all boxes */
.Box {
    background-color: #D3D3D3;
    padding: 20px;
    height: 200px;
    width: 200px;
    border-radius: 10px;
}

/* Styling for h2 headers */
.Header {
    color: black;
    font-size: 18px;
    font-weight: normal;
}

/* Container styling */
  
  /* Content styling */
  
  
  /* Buttons styling */
  .buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center; /* Center buttons horizontally */
  }
  
  .btn {
    padding: 12px 30px;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 15px;
    background-color: #c75425; /* Set background color to #c75425 */
    color: #fff; /* Set text color to white */
  }
  
  .btn-learn-more {
    padding: 10px 20px; /* Button padding */
    border: none; /* No border */
    border-radius: 50px; /* Rounded buttons */
    font-size: 1em; /* Button text size */
    cursor: pointer; /* Pointer cursor */
    min-width: 150px; /* Minimum width for buttons */
    text-align: center; /* Center text in buttons */
    background-color: #c75425; /* Button background color */
    color: #fff; /* Button text color */
    transition: background-color 0.3s; /* Transition effect */
  }
  
  .btn-learn-more:hover {
    background-color: #ffca76; /* Hover effect */
    color: #000; /* Change text color on hover */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .section-container {
      flex-direction: column;
      text-align: center;
    }
  
    .section-content {
      max-width: 100%;
    }
  
    .section-image {
      max-width: 100%;
      margin-top: 20px;
    }
  
    .btn {
      margin-bottom: 15px;
    }
  }
  
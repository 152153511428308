/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #F2F2F2 !important;
}

.link {
  text-decoration: none;
}

.MuiContainer-root {
  max-width: 1367px !important
}

.animate-line {
  animation: 10s linear infinite alternate animate-line;
}

.headingBoxWidth {
  max-width: 1100px;
  margin: auto;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 1536px) {
  .animate-line img {
    opacity: 1 !important;
  }
}

@media screen and (max-width: 600px) {
  .animate-line {
    animation-duration: 25s !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .animate-line {
    animation-duration: 25s !important;
  }
}

@keyframes animate-line {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

/* html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #fff;
  color: #fff;
  font-family: 'Centra', sans-serif !important;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  /* margin: 0;
  padding: 0; */
  line-height: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* Center the container horizontally and vertically */
.email-container {
    display: flex;
    align-items: center;
    justify-content: center; /* To center the flex items horizontally */
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 100px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: fit-content;
    margin: 20px auto; /* Auto to center it horizontally */
    margin-top: -25px;
  }
  
  .icon-container {
    background-color: #c94c16;
    border-radius: 50%;
    padding: 10px;
  }
  
  .icon {
    width: 30px;
    height: 30px;
  }
  
  .text-container {
    margin-left: 10px;
  }
  
  .title {
    font-size: 12px;
    color: #c94c16;
    font-weight: bold;
    margin: 0;
  }
  
  .email-link {
    font-size: 14px;
    color: #000;
    text-decoration: none;
    font-weight: normal;
  }
  